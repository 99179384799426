
.section-title{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.section-title h1{
    font-size: 35px;
    font-weight: 700;
    color: #27ae60;
    margin-bottom: 40px;
}

.section-line {
    width: 192px;
    height: 1px;
    margin-bottom: 35px;
    background-color: #27ae60;
}


.feature-products-container {
    margin: 0 400px; /* Original margin */
    margin-top: 65px;
}

/* Mobile view adjustments */
@media (max-width: 768px) {
    .feature-products-container {
        margin: 0 10px; /* Adjust margin to fit mobile view */
        margin-top: 55px;
    }

    .slider-container {
        margin-left: 0; /* Align to the left */
        padding-left: 10px; /* Add padding if needed */
    }

    .section-title {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 2px;
    }
    
    .section-title h1 {
        font-size: 35px; /* Adjusted font size for mobile view */
        font-weight: 700;
        color: #27ae60;
        margin-top: -40px; /* Adjust this value to move the h1 lower */
        margin-right: 0px; /* Adjust this value to move the h1 to the left */
        text-align: center; /* Adjust text alignment if needed */
    }
    
    .section-line {
        width: 192px;
        height: 1px;
        margin-bottom: 45px;
        background-color: #27ae60;
        margin: 0 auto 85px;
    }
}
