.container {
    margin: 25px 0;
    padding: 0 320px;
}

.main-display {
    display: flex;
    gap: 51px;
}

.display-first-section {
    position: relative;
    width: 801px;
    height: 590px;
    margin-left: 250px;
    border: 1px solid black;
}

@media (min-width: 769px) { /* Apply styles for desktop view */
    .content-sec-one {
        position: absolute;
        bottom: 30px; /* Adjusted bottom position */
        right: 10px; /* Adjusted right position */
        text-align: center; /* Align text to the right */
    
    }

    .content-sec-one h2 {
        margin-top: 120px; /* Adjusted margin to move the text lower */
    }

}

.content-sec-one h1,
.content-sec-one h2,
.content-sec-one h3 {
text-shadow: 2px 1px 4px rgb(255, 255, 255); /* Adjusted text shadow */
}

.meat-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: rotation 2s;
}

@keyframes rotation {
    0% {
        transform: translate(-50%, -50%) rotate(0deg) scale(0);
    }
    50% {
        transform: translate(-50%, -50%) rotate(180deg) scale(0);
    }
    100% {
        transform: translate(-50%, -50%) rotate(360deg) scale(1);
    }
}


.content-sec-one {
    position: absolute;
    right: 30px;
    top: 110px;
}

.content-sec-one h2 {
    font-size: 48px;
    color: #000000;
}

.content-sec-one h1 {
    font-size: 100px;
    font-weight: 500;
    line-height: 88px;
}

.content-sec-one h3 {
    font-size: 45px;
    font-weight: 800;
    line-height: 70px;
    text-align: right;
}

@media (min-width: 769px) { /* Apply styles for desktop view */
    .grid-container {
        width: 20%; /* Adjusted width */
        margin-left: -40px; /* Adjusted negative margin */
    }
}


.relative {
    position: relative;
}

.content-section-two,
.content-section-three {
    position: absolute;
    width: 350px;
    top: 0;
    bottom: 0;
}

.content-section-two {
    left: calc(100% + 10px);
    z-index: 1;
    left: 10px;
}

.content-section-three {
    left: calc(100% + 10px);
    z-index: 1;
    top: 450px;
    left: 10px;
}

.content-section-two h1 {
    font-size: 82px;
    font-weight: 900;
    color: #605E5E;
    line-height: 70px;
    text-shadow: 2px 1px 4px rgb(255, 255, 255);
}

.content-section-two h2,
.content-section-three h2 {
    font-size: 32px;
    font-weight: 900;
    color: #674F05;
    line-height: 40px;
    text-shadow: 2px 1px 4px rgb(255, 255, 255);
}

.content-section-three h1 {
    font-size: 82px;
    font-weight: 900;
    color: #2fbce7;
    line-height: 70px;
    text-shadow: 2px 1px 4px rgb(3, 3, 3);
}

.content-section-three h2 {
    font-size: 32px;
    font-weight: 800;
    color: #27ae60;
    line-height: 40px;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: rgb(0, 207, 69);
    text-shadow: 2px 1px 4px rgb(3, 3, 3);
}

.column div {
    position: relative;
    float: left;
    padding: 0;
}

figure {
    width: 500px;
    height: 291px;
    margin: 0 0 8px;
    padding: 0;
    background: transparent;
    overflow: hidden;
}

.hover05 figure img {
    margin-top: 50px;
    margin-left: 100px;
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
    -webkit-transition: .6s ease-in-out;
    transition: .6s ease-in-out;
}

.hover05 figure:hover img {
    margin-left: 0;
}

.sub-section {
    display: flex;
    margin-top: 30px;
    margin-left: 245px;
    gap: 80px;
}

.sub-section-cont {
    min-height: 110px;
}

.main-section {
    display: flex;
    align-items: center;
    gap: 22px;
}

.main-section h2 {
    font-size: 19px;
    font-weight: 700;
    color: #27ae60;
}

.main-section p {
    font-size: 13px;
    color: #7E7C7C;
}

.sub-section>.sub-section-cont::after {
    content: "";
    display: block;
    margin: auto;
    height: 3px;
    width: 0;
    background: transparent;
    transition: all 0.3s;
}

.sub-section>.sub-section-cont:hover::after {
    margin-top: 5px;
    width: 80%;
    background: #27ae60;
}

.separator {
    margin-top: 30px;
}


/* Mobile view adjustments */
@media (max-width: 768px) {
    .display-first-section {
        width: 408px; /* Adjusted width */
        height: 301px; /* Adjusted height */
        margin-left: calc(50% - 204px); /* Center the section horizontally */
        margin-bottom: 20px; /* Added margin bottom for spacing */
    }

    .meat-image {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        animation: rotation 2s; /* Keep animation for mobile view */
    }

    @keyframes rotation {
        0% {
            transform: translate(-50%, -50%) rotate(0deg) scale(0);
        }
        50% {
            transform: translate(-50%, -50%) rotate(180deg) scale(0);
        }
        100% {
            transform: translate(-50%, -50%) rotate(360deg) scale(1);
        }
    }

    .main-display {
        flex-direction: column;
        gap: 20px;
    }

    .display-first-section {
        padding: 5px;
        text-align: center;
        width: auto;
        margin-left: 0;
    }

    .content-sec-one {
        position: absolute;
        bottom: 10px; /* Adjusted bottom position */
        right: 10px; /* Adjusted right position */
        text-align: center; /* Align text to the right */
    }

    .content-sec-one h2 {
        font-size: 24px; /* Adjusted font size */
        font-weight: bold;
    }

    .content-sec-one h1 {
        font-size: 48px; /* Adjusted font size */
        line-height: 44px; /* Adjusted line height */
    }

    .content-sec-one h3 {
        font-size: 18px; /* Adjusted font size */
        line-height: 25px; /* Adjusted line height */
    }

    .content-sec-one h1,
    .content-sec-one h2,
    .content-sec-one h3 {
    text-shadow: 2px 1px 4px rgb(255, 255, 255); /* Adjusted text shadow */
    }

    .content-section-two,
    .content-section-three {
        position: absolute;
        width: 350px;
        top: 0;
        bottom: 0;
        
    }

    .content-section-two {
        left: calc(100% + 10px);
        z-index: 1;
        left: 10px;
    
    }

    .content-section-three {
        left: calc(100% + 10px);
        z-index: 1;
        top: 450px;
        left: 10px;
    }

    .content-section-two h1 {
        font-size: 42px;
        line-height: 20px;
        font-weight: 900;
        color: #605E5E;
        text-shadow: 2px 1px 4px rgb(255, 255, 255);
        margin-top: 4px; /* Adjust this value to move it lower */
        margin-bottom: 10px; 
    }

    .content-section-two h2,
    .content-section-three h2 {
        font-size: 16px;
        line-height: 15px;
        font-weight: 900;
        color: #674F05;
        text-shadow: 2px 1px 4px rgb(255, 255, 255);
        margin-top: 0px;
    }

    .content-section-three h1 {
        font-size: 42px;
        line-height: 40px;
        font-weight: 900;
        color: #2fbce7;
        text-shadow: 2px 1px 4px rgb(8, 8, 8);
        margin-top: 110px; /* Adjust this value to move it lower */
        margin-left: 260px;
    }

    .content-section-three h2 {
        font-size: 22px;
        font-weight: 800;
        color: #27ae60;
        line-height: 20px;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: rgb(0, 207, 69);
        text-shadow: 2px 1px 4px rgb(3, 3, 3);
        margin-top: 4px; /* Adjust this value to move it closer to h1 */
        margin-left: 260px;
    }

    figure {
        width: auto;
        height: 360px;
        margin: 0 0 8px;
        padding: 0;
        background: transparent;
        overflow: hidden;
        margin-top: -30px;
    }

    .hover05 figure img {
        margin-top: 25px;
        margin-left: 40px;
        -webkit-transform: scale(1.2);
        transform: scale(1.2);
    }



    .hover05 figure {
        width: 410px; /* Adjust the width as needed */
        margin-right: 10px; /* Adjust the right margin as needed */
    }

    .sub-section {
        flex-direction: column;
        gap: 15px; /* Adjust gap for mobile view */
        margin-left: 20px;
    }

    .sub-section .sub-section-item {
        width: 100%;
        /* Add padding or margin if needed */
    }

    .sub-section-cont {
        flex: 1 1 auto;
    }

    .main-section {
        display: flex; /* Ensure the container is a flex container */
        flex-direction: column; /* Stack children vertically */
        justify-content: center; /* Center children vertically */
        align-items: center; /* Center children horizontally */
        height: 22vh; /* Adjust height to ensure centering within the viewport */
        text-align: center; /* Center text inside the container */
    }
    

    .rotating-img-container {
        position: relative;
        top: auto;
        left: 50%;
        transform: translateX(-50%);
        margin-bottom: 20px;
    }

    .grid-container {
        gap: 10px;
        justify-items: center;
    }
}
    @media (max-width: 375px) {
        .display-first-section {
          margin-right: -2px; /* Adjust this value as needed */
        }

        figure {
            width: auto;
            height: 360px;
            margin-left: -25px;
            padding: 0;
            background: transparent;
            overflow: hidden;
            margin-top: -30px;
        }

        .content-section-three h1 {
            font-size: 42px;
            line-height: 40px;
            font-weight: 900;
            color: #2fbce7;
            text-shadow: 2px 1px 4px rgb(8, 8, 8);
            margin-top: 110px; /* Adjust this value to move it lower */
            margin-left: 225px;
        }

        .content-section-three h2 {
            margin-left: 215px;
        }

        .sub-section {
            flex-direction: column;
            gap: 55px; /* Adjust gap for mobile view */
            margin-left: 20px;
        }
}

@media (max-width: 390px) {
    figure {
        width: auto;
        height: 360px;
        margin-left: -25px;
        padding: 0;
        background: transparent;
        overflow: hidden;
        margin-top: -30px;
    }
}