/* Ensures the entire page is used for centering */
.contact-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f0f0f0; /* optional background color for the page */
}

.contact-container {
  margin: 10px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  max-width: 600px;
  background-color: #f9f9f9;
  width: 100%;
  margin-top: -50px;
}

.contact-title {
  font-size: 24px;
  margin-bottom: 10px;
  color: #333;
  text-align: center;
}

.contact-text {
  font-size: 16px;
  margin-bottom: 20px;
  color: #555;
  text-align: center;
}

textarea {
  max-width: 100%;
  min-width: 100%;
  width: 100%;
  min-height: 200px;
  padding: 7px;
  border-radius: 5px;
  border: 1px solid rgb(220, 220, 220);
}

.form-group {
  margin-bottom: 35px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

button[type="submit"] {
  background-color: #27ae60;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: block;
  margin: 0 auto;
}

button[type="submit"]:hover {
  background-color: #219150;
}

.notification {
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 4px;
  font-size: 14px;
  text-align: center;
  animation: fadeIn 0.5s, fadeOut 0.5s 5.5s;
}

.notification.success {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
}

.notification.error {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-10px);
  }
}

@media (max-width: 375px) {
  .contact-container {
    margin: 10px;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    max-width: 800px;
    background-color: #f9f9f9;
    width: 100%;
    margin-top: 20px;
    height: auto;
  }

}


@media (max-width: 430px) {
  .contact-container {
    margin: 10px;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    max-width: 800px;
    background-color: #f9f9f9;
    width: 100%;
    margin-top: -120px;
    height: auto;
  }

}
