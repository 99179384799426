/* Navbar.css */

.navbar-container {
    width: 100%;
    height: 58px;
    background-color: #27ae60;
    margin-top: 8px;
    padding: 0 20px; /* Adjust padding for mobile */
    display: flex;
    align-items: center;
}

.navbar-sub-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%; /* Ensure full width */
}

.navbar {
    flex: 1; /* Take remaining space */
}

.navbar ul {
    display: flex;
    gap: 20px; /* Default gap for mobile */
    list-style: none; /* Remove default list style */
    padding: 0; /* Remove default padding */
    margin: 0; /* Remove default margin */
    justify-content: center; /* Center the menu items horizontally */
}

.navbar ul li {
    margin-right: 20px; /* Default margin for mobile */
}

.anim-nav a {
    position: relative;
    text-decoration: none;
    color: #fff;
    font-size: 16px; /* Adjust font size for mobile */
}

.anim-nav a:before {
    position: absolute;
    content: '';
    -webkit-transition: all 0.35s ease;
    transition: all 0.35s ease;
    opacity: 0;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-left: 1px solid transparent; /* Adjust the width of the left border */
    border-right: 1px solid transparent; /* Adjust the width of the right border */
    border-color: #ffffff; /* Hide the borders */
}

.anim-nav a:hover,
.anim-nav .current a {
    color: #ffffff;
}

.anim-nav a:hover:before,
.anim-nav .current a:before {
    opacity: 1;
    left: 0;
    right: 0;
}

/* Media query for iPhone SE */
@media (max-width: 375px) and (max-height: 667px) {
    .navbar-container {
        padding: 0 10px; /* Adjusted padding for iPhone SE */
        height: 48px; /* Adjusted height for iPhone SE */
    }

    .navbar ul {
        gap: 10px; /* Adjusted gap for iPhone SE */
    }

    .navbar ul li {
        margin-right: 10px; /* Adjusted margin for iPhone SE */
    }

    .anim-nav a {
        font-size: 14px; /* Adjusted font size for iPhone SE */
    }
}
