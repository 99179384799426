.blog-container {
    margin-left: 220px;
    margin-top: 180px;
    position: relative;
}

.blog-icon {
    position: absolute;
}

.blog-content {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
}

.blog-item {
    flex: 1;
}

.blog-cont-img {
    position: relative;
    cursor: pointer;
}

.blog-img {
    width: 90%;
    height: auto;
    margin-bottom: 25px
}

.blog-text {
    padding: 80px;
}

.blog-title {
    font-size: 35px;
    font-weight: 700;
}

.blog-desc {
    font-size: 13px;
    color: #000000;
    margin-top: 16px;
}

.blog-tag {
    margin-top: 18px;
}

.blog-tags {
    display: flex;
    gap: 18px;
    margin-top: 8px;
}

.blog-tag-circle {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #D9D9D9;
}

.blog-tag-circle.green {
    width: 18px;
    background-color: #2AC01D;
}

@media (max-width: 768px) {
    .blog-container {
        margin-left: 0;
        margin-top: 125px; /* Adjusted top margin for mobile */
        padding-top: 20px; /* Added padding to top */
    }

    .blog-icon {
        position: relative;
        left: 0;
        top: 0;
        transform: translate(0, 0);
    }

    .blog-content {
        flex-direction: column;
        margin-top: -830px;
    }

    .blog-item {
        margin-bottom: 40px;
    
    }

    .blog-text {
        padding: 20px;
    }

    .blog-desc{
        font-weight: bold;
    }

    .blog-tag{
        font-weight: bold;
    }

    .blog-desc{
        margin-left: 20px;
    }
}


@media (max-width: 768px){
    .blog-img {
        width: 90%;
        height: auto;
        margin-left: 22px;
    }

    .blog-title {
        text-align: center;
    }

    .blog-desc{
        margin-left: 20px;
    }
}

