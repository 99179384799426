/* Global reset and basic styles */
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    font-family: 'Inter', sans-serif;
    margin: 0;
    padding: 0;
    overflow-x: hidden; /* Prevent horizontal scrolling */
}

.container {
    max-width: 100%;
    margin: 0 auto;
    padding: 20px;
}

/* Add responsive styles for smaller screens */
@media (max-width: 768px) {
    .container {
        padding: 10px;
    }
}

img {
    max-width: 100%;
    height: auto;
}

/* Add more styles as needed */
