/* Brands.css */

.brand-images {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.brandImg {
    max-width: 100%;
    height: auto;
    transition-duration: .4s;
}

.brandImg:hover {
    transform: scale(1.2);
}

/* Specific style for the larger image */
.brandImg-large {
    width: 150px; /* Adjust this size as needed */
    height: auto;
}

/* Adjustments for mobile view */
@media (max-width: 768px) {
    .brand-images {
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 10px; /* Adjust top margin as needed */
    }

    .brandImg {
        width: 100px; /* Adjust width for mobile view */
        height: auto;
        margin: 5px; /* Add some margin to separate the images */
    }

    /* Specific style for the larger image on mobile */
    .brandImg-large {
        width: 180px; /* Adjust this size for mobile view as needed */
        height: auto;
    }
}
