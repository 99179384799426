.header-container {
    margin: 8px 320px 0px 320px;
    display: flex;
    justify-content: space-between;
}

/* Mobile view adjustments */
@media (max-width: 768px) {
    .header-container {
        margin: 8px 16px; /* Adjust margins for mobile */
        padding: 0 16px; /* Adjust padding for mobile */
        flex-wrap: wrap; /* Allow items to wrap on smaller screens */
    }

    .logo-search-cont {
        width: 100%; /* Ensure full width */
        justify-content: center; /* Center items */
        gap: 16px; /* Reduce gap for mobile */
        margin-left: 120px; /* Move logo slightly to the right */
    }

    .search-cont {
        width: 100%; /* Full width for search container */
        margin-top: 8px; /* Add margin for spacing */
        padding: 0 16px; /* Adjust padding for mobile */
    }

    .search-cont input {
        width: calc(100% - 44px); /* Full width minus padding and icon */
    }

    .contact-social-cont {
        width: 100%; /* Ensure full width */
        justify-content: center; /* Center items */
        gap: 16px; /* Reduce gap for mobile */
        margin-top: 8px; /* Add margin for spacing */
    }

    .social-icon-cont {
        justify-content: center; /* Center items */
        gap: 16px; /* Reduce gap for mobile */
    }

    .contact-social-cont .contact p {
        font-size: 16px; /* Reduce font size for mobile */
        margin-left: 8px; /* Adjust margin for mobile */
    }

    .headerIcon {
        width: 48px; /* Reduce icon size for mobile */
        height: 48px; /* Reduce icon size for mobile */
    }

    .headerIcon-size {
        width: 24px; /* Reduce icon size for mobile */
        height: 24px; /* Reduce icon size for mobile */
    }
}

.logo-search-cont img {
    width: 146px;
    height: 93px;
}

.search-cont {
    width: 280px;
    height: 41px;
    background-color: #c0c0c0;
    border-radius: 20.5px;
    display: flex;
    align-items: center;
    padding-left: 16px;
    color: #27ae60;
}

.search-cont .icon {
    width: 28px;
    height: 28px;
    margin-right: 8px;
    color: #070707;
}

.search-cont input {
    width: 350px;
    height: 41px;
    border-radius: 30.5px;
    background-color: transparent;
}

.search-cont input:focus {
    outline: none;
}

.contact-social-cont {
    display: flex;
    align-items: center;
    gap: 46px;
}

.contact-social-cont .contact {
    display: flex;
    align-items: center;
}

.contact-social-cont .contact p {
    font-size: 18px;
    font-weight: 600;
    margin-left: 17px;
}

.contact-social-cont .contact span {
    font-weight: 500;
}

.social-icon-cont {
    display: flex;
    align-items: center;
    gap: 25px;
}

.headerIcon {
    width: 58px;
    height: 58px;
    border-radius: 50%;
    display: grid;
    align-content: center;
    justify-content: center;
    background-color: #c0c0c0;
    transition-duration: .5s;
}

.call-icon path {
    background-color: #cfcfcf;
}

.headerIcon-size {
    width: 30px;
    height: 30px;
}

.headerIcon:hover {
    background-color: #27ae60;
    transform: scale(1.1);
}
