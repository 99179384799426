.swiper-button-next,
.swiper-button-prev {
    border: 1px solid #27ae60;
    padding: 30px;
    border-radius: 50%;
    margin: 0px -10px;
    color: #9A9A9A !important;
}

.hoverIncrease {
    padding: 20px;
    text-align: center;
    display: grid;
    justify-content: center;
    transition-duration: .3s;
}

.hoverIncrease img {
    height: 320px;
    margin: 0px auto;
}

.hoverIncrease .content {
    margin-top: 10px;
}

.hoverIncrease .content h1 {
    font-size: 20px;
    font-weight: 500;
}

.hoverIncrease .content h2 {
    font-size: 18px;
    font-weight: 700;
    color: #27ae60;
}

.price-underline {
    width: 57px;
    height: 1px;
    background-color: black;
    margin: 0 auto;
}

.select-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    color: #000000;
    font-weight: 700;
    margin-top: 16px;
}

.hoverIncrease:hover {
    transform: scale(1.1);
}

/* Mobile view adjustments */
@media (max-width: 768px) {
    .mySwiper {
        width: 100%; /* Adjust width to fit the screen */
        margin-left: 0px; /* Align to the left */
        padding-left: 10px; /* Add some padding if needed */
    }
    
    .swiper-slide {
        width: 80%; /* Adjust the slide width */
    }

    .swiper-button-next,
    .swiper-button-prev {
        padding: 10px; /* Reduce padding for smaller buttons */
        border: 1px solid #27ae60;
        color: #27ae60 !important;
        top: 50%; /* Adjust position vertically */
        transform: translateY(-50%); /* Center vertically */
    }

    .swiper-button-next {
        right: 10px; /* Position to the right */
    }

    .swiper-button-prev {
        left: 10px; /* Position to the left */
    }

    .hoverIncrease img {
        height: 140px; /* Adjust image height */
        width: auto;
    }

    .hoverIncrease .content h1 {
        font-size: 14px; /* Reduce font size for mobile view */
    }

    .hoverIncrease .content h2 {
        font-size: 14px; /* Reduce font size for mobile view */
    }
}
