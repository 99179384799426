.swiper-button-next,
.swiper-button-prev {
    border: 0px solid #27ae60;
    padding: 10px; /* Default padding for larger screens */
    border-radius: 50%;
    color: #9a9a9a !important;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

@media (max-width: 768px) {
    .swiper-button-next,
    .swiper-button-prev {
        padding: 8px; /* Adjust padding for smaller screens */
        margin: -15px;
        top: auto;
        bottom: 150px; /* Adjust position for better visibility */
        transform: none; /* Remove translateY for default positioning */
    }
}
