/* General Footer styles */
.footer-container {
    margin-top: 29px;
    background-color: #27ae60;
    padding: 20px 0;
    position: relative;
    overflow: hidden;
}

.footer-container img {
    position: absolute;
}

.footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    padding-top: 23px;
}

.footer-info {
    width: 250px;
    height: 250px;
    z-index: 5;
}

.footer-info h2 {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 30px;
}

.footer-icons {
    display: flex;
    gap: 10px;
}

.hr-line {
    border-top: 1px solid #fff;
    margin: 24px 0;
}

.copyright {
    text-align: right;
}

/* Media queries for mobile view */
@media (max-width: 768px) {
    .footer-container {
        height: auto;
        padding: 20px 10px; /* Adjust padding to fit mobile view */
    }

    .footer-content {
        flex-direction: column;
        align-items: center; /* Center align all items */
        gap: 20px;
    }

    .footer-info {
        width: 100%;
        height: auto;
        text-align: center; /* Center align text */
        margin-bottom: 10px;
        font-weight: bold;
    }

    .footer-info h2 {
        font-size: 18px; /* Adjust font size */
        margin-bottom: 10px; /* Adjust margin */
        font-weight: bold;
    }

    .footer-info p {
        font-size: 14px; /* Adjust font size */
        font-weight: bold;
    }

    .footer-icons {
        justify-content: center; /* Center align icons */
        padding: 10px;
    }

    .footer-content img {
        display: none;
    }

    .hr-line {
        margin: 14px auto;
        width: 80%;
    }

    .copyright {
        text-align: center;
        width: 100%;
    }
}
