/* TopSell.css */

.mx-centered {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    text-align: center; /* Align text to center */
    margin-bottom: -185px;
}

.section-title2 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; /* Adjust to column layout for mobile */
    gap: 20px;
    margin-bottom: 85px; /* Adjust margin bottom for spacing */
}

.section-title2 h1 {
    font-size: 35px; /* Adjust font size for smaller screens */
    font-weight: 700;
    color: #27ae60;
    margin-bottom: 20px; /* Maintain similar margin */
    text-align: center; /* Align h1 text to center */
}

.section-line2 {
    width: 320px; /* Adjust width for consistency */
    height: 1px;
    background-color: #27ae60;
    margin: 0 auto; /* Center the element horizontally */
}

/* Media query adjustments */
@media (max-width: 768px) {
    .mx-centered {
        margin-top: 40px; /* Adjust top margin for mobile view */
    }

    .section-title2 {
        margin-bottom: 120px; /* Adjust margin bottom for mobile */
        margin-left: -150px;
    }

    .section-line2 {
        width: 160px; /* Adjust width for smaller screens */
        margin-bottom: -80px; /* Adjust margin bottom for mobile */
    }
}

@media (max-width: 375px) {
    .section-title2 h1{
        width: 160px; /* Adjust width for smaller screens */
        margin-bottom: 20px; 
        margin-left: -70px;
    }

    .section-line2 {
        width: 160px; /* Adjust width for smaller screens */
        margin-left: -70px; /* Adjust margin bottom for mobile */
    }
}

@media (max-width: 390px) {
    .section-title2 h1{
        width: 160px; /* Adjust width for smaller screens */
        margin-bottom: 20px; 
        margin-left: -70px;
    }

    .section-line2 {
        width: 160px; /* Adjust width for smaller screens */
        margin-left: -70px; /* Adjust margin bottom for mobile */
    }
}