/* General styles for products page */
.products-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f0f0f0; /* optional background color for the page */
}

.products-container {
  margin: 10px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  max-width: 800px;
  background-color: #f9f9f9;
  width: 100%;
  margin-top: 50px;
  height: auto;
}

.products-title {
  font-size: 24px;
  margin-bottom: 10px;
  color: #333;
  text-align: center;
}

.carousel-container {
  margin-bottom: 10px;
  margin-top: 20px;
}

.carousel .carousel-item {
  width: auto; /* Set width to auto */
}

.carousel .carousel-item img {
  max-width: 100%;
  border-radius: 8px;
}

.carousel .control-arrow {
  opacity: 0.5;
}

.carousel .control-arrow:hover {
  opacity: 1;
}

.carousel .carousel-indicators {
  display: flex; /* Show carousel indicators */
  justify-content: center;
  margin-top: 10px;
}

.carousel .legend {
  text-align: center;
  margin-top: 10px;
  font-size: 16px;
  color: #555;
}

.thumbnail-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 20px;
}

.thumbnail {
  width: 100px;
  height: 100px;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.3s;
}

.thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.thumbnail:hover {
  transform: scale(1.1);
}

/* Notification styles */
.notification {
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 4px;
  font-size: 14px;
  text-align: center;
  animation: fadeIn 0.5s, fadeOut 0.5s 5.5s;
}

.notification.success {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
}

.notification.error {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
}

/* Keyframe animations for notifications */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-10px);
  }
}

/* Media query for mobile view */
@media (max-width: 768px) {
  .products-container {
    margin: 10px;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    max-width: 800px;
    background-color: #f9f9f9;
    width: 100%;
    margin-top: -180px;
    height: auto;
  }

  .products-title {
    font-size: 18px;
  }

  .carousel .legend {
    font-size: 14px;
  }

  .thumbnail {
    width: 80px;
    height: 80px;
  }
}

@media (max-width: 375px) {
  .products-container {
    margin: 10px;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    max-width: 800px;
    background-color: #f9f9f9;
    width: 100%;
    margin-top: -20px;
    height: auto;
  }

}

@media (max-width: 430px) {
  .products-container {
    margin: 10px;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    max-width: 800px;
    background-color: #f9f9f9;
    width: 100%;
    margin-top: -320px;
    height: auto;
  }

}
