.location-container {
    margin: 20px auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    max-width: 800px;
    background-color: #f9f9f9;
    text-align: center;
  }
  
  .location-title {
    font-size: 24px;
    margin-bottom: 10px;
    color: #333;
  }
  
  .location-text {
    font-size: 16px;
    margin-bottom: 20px;
    color: #555;
  }
  
  .location-image {
    max-width: 100%;
    height: auto;
    margin-bottom: 20px;
    border-radius: 8px;
  }
  
  .map-container {
    width: 100%;
    margin: 20px 0;
  }

  @media (max-width: 768px) {
    .map-container {
      height: 150px;
      margin-top: 10px;
    }
  }